import Calendar from "js-year-calendar";
import 'js-year-calendar/locales/js-year-calendar.de';
import $ from 'jquery';

let tooltip = null;

if ($('#reservation-calendar').length > 0) {
    $(function () {
        function ConvertJsonDateToDateTime(date) {
            return new Date(date);
        }

        const data = $.getJSON('/reservation-calendar-data/')
            .done(function (responseData) {
                responseData.forEach((entry) => {
                    entry.startDate = ConvertJsonDateToDateTime(entry.startDate);
                    entry.endDate = ConvertJsonDateToDateTime(entry.endDate);
                })
                const calendar = new Calendar('#reservation-calendar', {
                    language: 'de',
                    dataSource: responseData,
                    clickDay: function (e) {
                        if (e.events.length > 0) {
                            let content = '';

                            for (const i in e.events) {
                                content += '<div class="event-tooltip-content">'
                                    + '<div class="event-name"><a href="/dayplanning/detail/' + e.events[i].id + '">' + e.events[i].name + '</a></div>'
                                    + '</div>';
                            }

                            if (tooltip !== null) {
                                setTimeout(() => {
                                    tooltip.destroy();
                                }, 2000);

                                tooltip = null;
                            }

                            tooltip = tippy(e.element, {
                                content: content,
                                placement: "right",
                                arrow: true,
                                allowHTML: true,
                                trigger: 'click',
                                hideOnClick: false,
                                interactive: true,
                            });
                            tooltip.show();
                        }
                    },
                });
            })
    })

    /*document.querySelector('#reservation-calendar').addEventListener('clickDay', function (e) {
        if (e.events.length > 0){
            const id = e.events[0].id;
            window.location.pathname = ('/dayplanning/detail/' + id);
        }

    });*/
}
